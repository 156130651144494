<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} System User</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Full Name"
                    placeholder="John Doe"
                    :rules="nameRules"
                    :error="form.errors.has('name')"
                    :error-messages="form.errors.get('name')"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.email"
                    label="Email"
                    placeholder="example@domain.com"
                    :error="form.errors.has('email')"
                    :error-messages="form.errors.get('email')"
                    :rules="emailRules"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="form.role"
                    :items="roles"
                    label="Role"
                    :rules="[v => !!v || 'Role is required']"
                    :error="form.errors.has('role')"
                    :error-messages="form.errors.get('role')"
                    :loading="isRolesLoading"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="form.stations"
                    :items="stations"
                    :loading="isStationsLoading"
                    :search-input.sync="stationSearch"
                    item-text="name"
                    item-value="id"
                    label="Stations"
                    placeholder="Select stations"
                    hint="Select the stations the user has access to"
                    persistent-hint
                    multiple
                    chips
                    deletable-chips
                    outlined
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>
                          Search for stations
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapGetters } from 'vuex'
import axios from 'axios'
import validationRules from '@/mixins/validationRules'

export default {
  mixins: [validationRules],
  props: {
    showDialog: {
      type: Boolean,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      email: '',
      role: '',
      user_type: '',
      stations: [], // Add stations array to form
    }),
    isRolesLoading: false,
    isStationsLoading: false,
    roles: [],
    stations: [],
    stationSearch: null,
    stationPage: 1,
    stationHasMore: true,
    action: 'Save',
  }),
  computed: {
    ...mapGetters(['selectedStation']),
  },
  watch: {
    user() {
      if (Object.keys(this.user).length > 0) {
        this.form.update({
          ...this.user,
          role: this.user.roles
            .filter(role => role.name.toLowerCase() !== 'administrator')
            .map(role => role.name)[0] || '',
          stations: this.user.station_accesses
            ? this.user.station_accesses.map(station => station.station_id)
            : [],
        })
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
    showDialog(value) {
      if (value) {
        if (this.roles.length === 0) {
          this.getRoles()
        }
        this.getStations()
      }
    },
    stationSearch(val) {
      // Debounce search input
      if (val) {
        this.searchStations()
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
      this.form.errors.clear()
      this.$refs.form.resetValidation()
      this.form = new Form({
        name: '',
        email: '',
        role: '',
        user_type: '',
        stations: [],
      })
    },
    getRoles() {
      this.isRolesLoading = true
      axios
        .get('roles')
        .then(response => {
          this.roles = response.data
          this.isRolesLoading = false
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isRolesLoading = false
        })
    },
    getStations(page = 1) {
      this.isStationsLoading = true
      axios
        .get(`stations?page=${page}`)
        .then(response => {
          const { data, current_page, last_page } = response.data

          // Append new stations if paginating, otherwise replace
          this.stations = page > 1
            ? [...this.stations, ...data]
            : data

          this.stationPage = current_page
          this.stationHasMore = current_page < last_page
          this.isStationsLoading = false
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.isStationsLoading = false
        })
    },
    searchStations() {
      // Implement debounced search for stations
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(() => {
        this.isStationsLoading = true
        axios
          .get(`stations?search=${this.stationSearch}`)
          .then(response => {
            this.stations = response.data.data
            this.isStationsLoading = false
          })
          .catch(error => {
            this.$toast.error(error.response.data.message)
            this.isStationsLoading = false
          })
      }, 500)
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveUser()
        } else {
          this.updateUser()
        }
      }
    },
    saveUser() {
      this.form
        .post('system-users')
        .then(() => {
          this.$toast.success('System User added successfully')
          const userEmail = this.form.email
          setTimeout(() => {
            this.$toast.success(`An email has been sent to ${userEmail} with details on how to set up their account.`)
          }, 1500)
          this.closeDialog()
          this.$emit('user-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
    updateUser() {
      this.form
        .put(`system-users/${this.user.id}`)
        .then(() => {
          this.$toast.success('System User updated successfully')
          this.closeDialog()
          this.$emit('user-added')
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>
